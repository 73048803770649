<script>
import axios from 'axios';

export default {
    data() {
        return {
            isInvalidName: false,
            isInvalidContacts: false,
            isInvalidType: false,
            isInvalidMessage: false,
            showAlert: false,
            name: '',
            contacts: '',
            type: 0,
            message: ''
        };
    },
    components: {
    },
    methods: {
        validateForm: function () {
            this.isInvalidName = !this.name;
            this.isInvalidContacts = !this.contacts;
            this.isInvalidType = !this.type;
            this.isInvalidMessage = !this.message;

            if(!(this.isInvalidName || this.isInvalidContacts || this.isInvalidType || this.isInvalidMessage))
                return true;
            
            return false;
        },
        sendContactForm: function() {
            var r = this.validateForm();
            if(r) {
                var data = {
                    name: this.name,
                    contacts: this.contacts,
                    type: this.type,
                    message: this.message
                };

                const vm = this;
				axios.post('/api/support/create', data).then(function () {
                    vm.showAlert = true;
                    vm.name = '';
                    vm.contacts = '';
                    vm.type = 0;
                    vm.message = '';
                });

            }
        }
    }
};
</script>

<template>

    <b-alert :show="showAlert" variant="success" dismissible>
        <strong>{{ $t('t-contacts-alert-title') }}</strong> {{ $t('t-contacts-alert-description') }}
    </b-alert>

    <div class="live-preview">
        <form class="row g-3 needs-validation">

            <div class="col-md-12">
                <label for="name" class="form-label">{{ $t('t-contacts-form-name') }}</label>
                <input type="text" class="form-control" v-bind:class="{ 'is-invalid': isInvalidName }" id="name" v-model="name" maxlength="32">
                <div class="invalid-feedback">{{ $t('t-contacts-form-validation') }}</div>
            </div>

            <div class="col-md-12">
                <label for="contacts" class="form-label">{{ $t('t-contacts-form-contacts') }}</label>
                <input type="text" class="form-control" v-bind:class="{ 'is-invalid': isInvalidContacts }" id="contacts" v-model="contacts" maxlength="64">
                <div class="invalid-feedback">{{ $t('t-contacts-form-validation') }}</div>
            </div>

            <div class="col-md-12">
                <label for="type" class="form-label">{{ $t('t-contacts-form-request') }}</label>
                <select class="form-select" v-bind:class="{ 'is-invalid': isInvalidType }" id="type" v-model.number="type">
                    <option selected value="0"></option>
                    <option value="2">{{ $t('t-contacts-form-request-2')}}</option>
                    <option value="3">{{ $t('t-contacts-form-request-3')}}</option>
                    <option value="4">{{ $t('t-contacts-form-request-4')}}</option>
                    <option value="1">{{ $t('t-contacts-form-request-1')}}</option>
                </select>
                <div class="invalid-feedback">{{ $t('t-contacts-form-validation') }}</div>
            </div>

            <div class="col-md-12">
                <label for="message" class="form-label">{{ $t('t-contacts-form-message') }}</label>
                <textarea class="form-control" v-bind:class="{ 'is-invalid': isInvalidMessage }" id="message" rows="10" v-model="message" maxlength="4096"></textarea>
                <div class="invalid-feedback">{{ $t('t-contacts-form-validation') }}</div>
            </div>

            <div class="col-12 text-center">
                <button class="btn btn-primary" type="button" @click="sendContactForm">{{ $t('t-contacts-button-send') }}</button>
            </div>

        </form>
    </div>
</template>
